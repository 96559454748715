import React from 'react'
import tw, {styled} from 'twin.macro'

const Container = styled.a(() => [
  tw`relative flex items-center justify-center w-52 lg:w-80 h-10 lg:h-16 bg-green-400 text-white font-din font-extrabold text-lg lg:text-3xl cursor-pointer`,
  `box-shadow: 5.5px 5.5px 0 #4f4f4f;
   transition: all 250ms;
   &:after {
     content: '';
     position: absolute;
     border-top: 7px solid #4f4f4f;
     border-right: 7px solid #4f4f4f;
     border-bottom: 7px solid transparent;
     border-left: 7px solid transparent;
     top: 8px;
     right: 9px;
     @media (max-width: 1023px) {
       border-width: 5px;
       top: 6px;
       right: 7px;
     }
   }
   &:hover {
     transform: translate(-1.5px, -1.5px);
     box-shadow: 7px 7px 0 #4f4f4f;
   }
   &:active {
     transform: translate(5.5px, 5.5px);
     box-shadow: 0 0 0 #4f4f4f;
   }`
])

export const ButtonLinkExternal = (props) => {
  const { children } = props
  return (
    <Container {...props}>
      {children}
    </Container>
  )
}
