import React from 'react'
import tw, {styled} from 'twin.macro'
import {useI18n} from "use-mini18n"
import {SectionTitle} from "../../components/Typography"
import {WrapContent2Row} from "../../components/Layout"
import BgDeco from "../../assets/images/FakeJapanese05-Suport.svg"
import StartbahnLogoImg from "../../assets/images/startbahn_logo.png"
import StartbahnCertLogoImg from "../../assets/images/startbahn_cert_logo.svg";

const Container = styled.section(() => [
  tw`relative w-full py-12 px-4 lg:pr-44 lg:pl-16 transition-all duration-200 text-gray-100`,
  `background-color: #7a7a7a;`
])
const ContentsInner = tw.div`py-10`
const WrapLogo = tw.div`flex items-center justify-center w-full lg:w-1/2`
const DecoFakeJapanese = styled.div(() => [
  tw`absolute top-0 right-0 z-0`,
  `width: 9vw;
   height: 30vw;
   background-image: url(${BgDeco});
   background-size: contain;
   background-repeat: no-repeat;
  `
])
const WrapStartbahnLogo = tw.a`block w-72`
const StartbahnLogo = tw.img`w-full`
const WrapStartbahnCertLogo = tw.a`block w-40 h-40`
const StartbahnCertLogo = tw.img`w-full h-full object-contain`

export const Support = (props) => {
  const {t} = useI18n()
  const { _ref } = props
  return (
    <Container id="support" ref={_ref}>
      <DecoFakeJapanese />
      <SectionTitle>{t['support_title']}</SectionTitle>
      <ContentsInner>
        <WrapContent2Row>
          <WrapLogo>
            <WrapStartbahnLogo href={'https://startbahn.jp/en/'} target="_blank">
              <StartbahnLogo src={StartbahnLogoImg} />
            </WrapStartbahnLogo>
          </WrapLogo>
          <WrapLogo>
            <WrapStartbahnCertLogo href={'https://cert.startbahn.io/en'} target="_blank">
              <StartbahnCertLogo src={StartbahnCertLogoImg} />
            </WrapStartbahnCertLogo>
          </WrapLogo>
        </WrapContent2Row>
      </ContentsInner>
    </Container>
  )
}
