import tw from 'twin.macro'

export const SectionTitle = tw.h2`font-din-narrow lg:font-din font-extrabold text-3xl lg:text-8xl`

export const ContentTitle = tw.h3`font-din italic font-semibold text-xl lg:text-3xl mb-2 lg:mb-4`

export const ContentText = tw.p`font-din font-semibold text-lg tracking-wide leading-7`
export const ContentTextSmall = tw.p`font-din font-semibold text-sm tracking-wide leading-7 break-all`

export const Highright = tw.span`bg-yellow-500`
