import React from 'react'
import tw, {styled} from 'twin.macro'
import {useI18n} from "use-mini18n"
import {mdiTwitter, mdiTextBoxCheck, mdiCart} from '@mdi/js'
import Icon from '@mdi/react'
import BgDeco from "../../assets/images/FakeJapanese06-Team.svg"
import {SectionTitle, ContentText, ContentTextSmall} from "../../components/Typography"
import {WrapContent2Row} from "../../components/Layout"
import AkihiroImg from "../../assets/images/akihiro-kato.png";
import AsamiImg from "../../assets/images/asami-kato.png";

const Container = tw.section`
  relative w-full py-12 lg:py-16 px-4 lg:pr-44 lg:pl-16 transition-all duration-200
`
const ContentsInner = tw.div`py-10`
const WrapLogo = tw.div`flex items-center justify-center w-full lg:w-1/2`
const DecoFakeJapanese = styled.div(() => [
  tw`absolute top-0 right-0 z-0`,
  `width: 9vw;
   height: 10vw;
   background-image: url(${BgDeco});
   background-size: contain;
   background-repeat: no-repeat;
  `
])
const WrapMember = tw.div`block w-72`
const WrapImage = tw.img`w-full`
const TeamLinkText = tw.a`flex text-blue-400 gap-x-2 font-din-narrow mt-2`
const DonateTeam = tw.div`mt-6 text-center`

export const Team = (props) => {
  const {t} = useI18n()
  const { _ref } = props
  return (
    <Container id="team" ref={_ref}>
      <DecoFakeJapanese />
      <SectionTitle>{t['team_title']}</SectionTitle>
      <ContentsInner>
        <WrapContent2Row>
          <WrapLogo>
            <WrapMember>
              <WrapImage src={AkihiroImg} />
              <TeamLinkText href="https://akihirokato.com/cv" target="_blank">
                <Icon path={mdiTextBoxCheck} size={1} />CV
              </TeamLinkText>
              <TeamLinkText href="https://twitter.com/croe_c" target="_blank">
                <Icon path={mdiTwitter} size={1} />Twitter
              </TeamLinkText>
              <TeamLinkText href="https://www.amazon.jp/hz/wishlist/ls/4VLVOULVMAGD?ref_=wl_share" target="_blank">
                <Icon path={mdiCart} size={1} />Amazon wish list
              </TeamLinkText>
            </WrapMember>
          </WrapLogo>
          <WrapLogo>
            <WrapMember>
              <WrapImage src={AsamiImg} />
            </WrapMember>
          </WrapLogo>
        </WrapContent2Row>
        <DonateTeam>
          <ContentText>Please donate to team too! 😂</ContentText>
          <ContentTextSmall>0x0ec2A566DFd791aB2919AEE895d702433B4f5894</ContentTextSmall>
        </DonateTeam>
      </ContentsInner>
    </Container>
  )
}
