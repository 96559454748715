import React from 'react'
import tw from 'twin.macro'

const Container = tw.footer`flex flex-col items-center justify-center bg-gray-700 w-full py-6 text-gray-100`
const Copyright = tw.p`font-din`
const PrivacyPolicy = tw.a`font-din block text-center text-blue-500`

export const Footer = () => {
  return (
    <Container>
      <Copyright>&copy;2021 by Team nekonikobahn ❤️ With love to dogs and cats️</Copyright>
      <PrivacyPolicy href="https://croe.notion.site/Privacy-policy-d65c9b273860407b934b5a0d006a8c2c" target="_blank">Privacy Policy(JP)</PrivacyPolicy>
    </Container>
  )
}
