import React from 'react'
import tw, {styled} from 'twin.macro'
import AnchorLink from 'react-anchor-link-smooth-scroll'

const Container = styled.p(() => [
  tw`relative flex items-center justify-center w-52 lg:w-80 h-10 lg:h-16 bg-blue-400 text-white font-din font-extrabold text-lg lg:text-3xl cursor-pointer`,
  `box-shadow: 5.5px 5.5px 0 #4f4f4f;
   transition: all 250ms;
   > a {
     display: flex;
     width: 100%;
     height: 100%;
     align-items: center;
     justify-content: center;
   }
   &:after {
     content: '';
     position: absolute;
     border-top: 7px solid #4f4f4f;
     border-left: 7px solid transparent;
     border-bottom: 7px solid transparent;
     border-right: 7px solid transparent;
     bottom: 8px;
     right: 9px;
     @media (max-width: 1023px) {
       border-width: 5px;
       bottom: 3px;
       right: 7px;
     }
   }
   &:hover {
     transform: translate(-1.5px, -1.5px);
     box-shadow: 7px 7px 0 #4f4f4f;
   }
   &:active {
     transform: translate(5.5px, 5.5px);
     box-shadow: 0 0 0 #4f4f4f;
   }`
])

export const ButtonLinkInternal = (props) => {
  const { children, link } = props
  return (
    <Container {...props}>
      <AnchorLink href={link}>
        {children}
      </AnchorLink>
    </Container>
  )
}
