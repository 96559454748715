// eslint-disable-next-line import/no-anonymous-default-export
export default {
  CHAIN: 'polygon',
  // CHAIN: 'mumbai',
  // CHAIN: 'eth',
  NETWORK: 'mainnet',
  SEASON1_NYAN_ADDRESS: '0xA1B7EB77ec604aF4f9175860F7AFb213B778aE24',
  SEASON1_WAN_ADDRESS: '0x26045ff4b6fC1Bcaf013EadFF707E18772e24a07',
  SEASON1_TIME_LIMIT: 1634223599000,
  SEASON2_NYAN_ADDRESS: '0xc6324B33062f76591Fa068a21B871E28956c3b70',
  SEASON2_WAN_ADDRESS: '0x6A65e905345d536F0DF432E4fbe81F2585a30809',
  SEASON2_TIME_LIMIT: 1637333999000,
  SEASON3_NYAN_ADDRESS: '0xDFABDc040A96E1973d374Aee231De4BBE8d5fCDB',
  SEASON3_WAN_ADDRESS: '0x4081239A0c6AA8060f7eCd1294bbb47CE12598C6',
  SEASON3_TIME_LIMIT: 1650121199000,
  SEASON4_TIME_LIMIT: 1668337200000,
  MORALIS_ENV: {
    mainnet: {
      server: 'https://wkg2bqnfihei.bigmoralis.com:2053/server',
      appId: '3sNmJZmrPWzHdvlqtyfjQ8VCwbApX6MGI05XmWtB',
    },
    testnet: {
      server: 'https://idwlfgl1hyug.bigmoralis.com:2053/server',
      appId: 'RaJSpcNJCJESr4gHNwVhUmC4kzxZqGMbGA3ehYgX',
    }
  },
  DONATION_TOKEN: {
    mainnet: "0x7ceb23fd6bc0add59e62ac25578270cff1b9f619",
    testnet: "0xfe4f5145f6e09952a5ba9e956ed0c25e3fa4c7f1",
  }
}
