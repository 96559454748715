import React, {useEffect, useState} from 'react'
import { mdiLoading } from '@mdi/js'
import {useI18n} from "use-mini18n"
import Icon from '@mdi/react'
import tw, {styled} from 'twin.macro'
import { fetchEdition } from "../api"
import { useForm } from 'react-hook-form'
import { ButtonPopup } from "./ButtonPopup"

const Container = styled.div(() => [
  tw`p-4 rounded-lg overflow-y-scroll max-h-screen`,
  `background-color: #c1c1c1;`
])
const AlertNetwork = tw.p`font-din font-extrabold text-lg text-red-600 mb-4`
const WrapInput = tw.div`mb-4`
const InputLabel = tw.label`font-din text-gray-900`
const InputText = tw.input`block w-full p-2 border border-gray-300 text-gray-900 rounded-md`
const ErrorMessage = tw.p`font-din text-base text-red-500`
const WrapSubmit = tw.div`flex justify-center gap-x-4 mt-8`
const ResultEdition = tw.p`font-din text-gray-900 font-extrabold text-5xl text-center`
const Form = tw.form``

export const EditionCheckerModal = ({closeModal}) => {
  const {t} = useI18n()
  const [isChecked, setIsChecked] = useState(false)
  const [edition, setEdition] = useState(null)
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    criteriaMode: "all",
    shouldFocusError: false,
  })

  const handleCheckEdition = async (data) => {
    setIsChecked(true)
    const result = await fetchEdition(data)
    if (result.is_success) {
      setEdition(result.num)
    }
  }

  return (
    <Container>
      <AlertNetwork>{t['edition_checker_alert']}</AlertNetwork>
      {
        isChecked ? (
          <>
            <ResultEdition>{edition}/10</ResultEdition>
            <WrapSubmit>
              <ButtonPopup onClick={closeModal}>Close</ButtonPopup>
            </WrapSubmit>
          </>
        ) : (
          <Form onSubmit={handleSubmit(handleCheckEdition)}>
            <WrapInput>
              <InputLabel>{t['wan_nyan_token_id']}</InputLabel>
              <InputText type="text" {...register("token_id", { required: t['error_required'] })} />
              {errors.token_id && <ErrorMessage>{errors.name.token_id}</ErrorMessage>}
            </WrapInput>
            <WrapSubmit>
              <ButtonPopup onClick={closeModal}>{t['button_cancel']}</ButtonPopup>
              <ButtonPopup type="submit">{t['button_submit']}</ButtonPopup>
            </WrapSubmit>
          </Form>
        )
      }
    </Container>
  )
}
