import React from 'react'
import tw, {styled} from 'twin.macro'
import {useI18n} from "use-mini18n"
import { mdiTwitter } from '@mdi/js'
import Icon from '@mdi/react'
import Countdown from 'react-countdown'
import { DonationRatio } from '../../components/DonationRatio'
import { ButtonLinkInternal } from '../../components/ButtonLinkInternal'
import { ButtonLinkPopup } from '../../components/ButtonLinkPopup'
import { ReactComponent as Season4Logo} from '../../assets/images/logo-season4.svg'
import { orgRound } from "../../utils"
import { formatEther } from '@ethersproject/units'
import { getScanAddressURL } from "../../config/ethereum"
import { useMoralisWeb3Api, useMoralisWeb3ApiCall } from "react-moralis"
import firebaseDB from '../../config/firebase'
import { useDocument } from 'react-firebase-hooks/firestore';
import config from '../../const'
import dayjs from "dayjs";

const Container = tw.section`w-full pt-4 lg:pt-8 px-4 lg:pr-44 lg:pl-16 transition-all duration-200`
const WrapSeason = tw.div`w-64 lg:w-96 mb-7 lg:mb-12`
const WrapPercentage = tw.div`flex justify-between mt-6`
const EachPercentage = styled.div(({reverse}) => [ tw`flex lg:items-end items-start gap-x-8 lg:flex-row flex-col`, reverse && tw`lg:flex-row-reverse flex-col items-end` ])
const Percentage = styled.div(({reverse}) => [ reverse && tw`flex flex-col items-end` ])
const Counts = styled.div(({reverse}) => [ reverse && tw`flex flex-col items-end` ])
const SeasonDuration = tw.p`mt-4 font-din text-base lg:text-2xl font-bold whitespace-pre`
const TotalTitle = tw.p`flex flex-col lg:flex-row font-din text-base lg:text-2xl font-bold gap-x-2 items-start lg:items-end`
const TotalTitleReverse = tw.p`flex flex-col lg:flex-row font-din text-base lg:text-2xl font-bold gap-x-2 items-end`
const TotalValueTitle = tw.span`text-sm lg:text-base`
const TotalValue = tw.span`font-extrabold`
const Title = tw.a`text-2xl lg:text-3xl font-din font-bold`
const Value = tw.h3`text-5xl lg:text-7xl font-din font-extrabold`
const WrapSeasonLimit = tw.div`flex items-center mt-0 lg:mt-20 flex-col-reverse gap-y-4 lg:gap-y-8`
const LeftTime = tw.h3`text-2xl lg:text-5xl font-din font-extrabold whitespace-pre text-yellow-500`
const NextSeasonAnno = tw.p`text-lg lg:text-xl font-din font-semibold text-center`
const WrapAddressLink = tw.div`flex justify-between my-4`
const AlertNetwork = tw.p`font-din font-extrabold text-lg text-red-600 mb-4 mt-4 lg:mt-0`
const BreakSp = tw.br`block lg:hidden`

const Completionist = () => {
  const {t} = useI18n()
  return <LeftTime>{t['end_of_season']}</LeftTime>;
  // return (
  //   <>
  //     <NextSeasonAnno>{t['next_season_anno']}</NextSeasonAnno>
  //     <LeftTime>SEASON 3 WAN WIN!!!</LeftTime>
  //   </>
  // )
}
const renderer = ({ days, hours, minutes, seconds, completed }) => {
  if (completed) return <Completionist />;
  return <LeftTime>{days}D {hours}H {minutes}M {seconds}S LEFT</LeftTime>
}

export const WhatIs = (props) => {
  const {t} = useI18n()
  const { _ref } = props
  const WAN_OPTION = {
    chain: config.CHAIN,
    address: config.SEASON3_WAN_ADDRESS,
  }
  const NYAN_OPTION = {
    chain: config.CHAIN,
    address: config.SEASON3_NYAN_ADDRESS,
  }

  const [value, loading, error] = useDocument(
    firebaseDB.doc('season4/data'),
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  )
  const dogValue = loading ? 1 : value && value.data().dog
  const catValue = loading ? 1 : value && value.data().cat
  // const Web3Api = useMoralisWeb3Api()
  // const NyanTokens = useMoralisWeb3ApiCall(Web3Api.account.getTokenBalances, NYAN_OPTION)?.data
  // const WanTokens = useMoralisWeb3ApiCall(Web3Api.account.getTokenBalances, WAN_OPTION)?.data
  // const NyanDonationToken = NyanTokens?.filter(token => token.token_address === config.DONATION_TOKEN[config.NETWORK])[0]?.balance
  // const WanDonationToken = WanTokens?.filter(token => token.token_address === config.DONATION_TOKEN[config.NETWORK])[0]?.balance
  // const _NyanWalletBalance = useMoralisWeb3ApiCall(Web3Api.account.getNativeBalance, NYAN_OPTION)?.data?.balance
  // const _WanWalletBalance = useMoralisWeb3ApiCall(Web3Api.account.getNativeBalance, WAN_OPTION)?.data?.balance
  // const NyanWalletBalance = _NyanWalletBalance && _NyanWalletBalance > 0 ? Number(formatEther(_NyanWalletBalance)) : 0.0001
  // const WanWalletBalance = _WanWalletBalance && _WanWalletBalance > 0 ? Number(formatEther(_WanWalletBalance)) : 0.0001
  // const NyanWalletBalance = NyanDonationToken ? Number(formatEther(NyanDonationToken)) : 0.0001
  // const WanWalletBalance = WanDonationToken ? Number(formatEther(WanDonationToken)) : 0.0001
  const totalCount = dogValue + catValue
  // const PhysicalWAN = 32
  // const PhysicalNYAN = 32

  const DogRatio = totalCount && orgRound((dogValue / totalCount) * 100, 2)
  const CatRatio = 100 - DogRatio
  const GetLeftTime = () => {
    const LeftTime = dayjs(config.SEASON4_TIME_LIMIT).diff(dayjs(), 'minute')
    let d = Math.floor(LeftTime/1440); // 60*24
    let h = Math.floor((LeftTime-(d*1440))/60);
    let m = Math.round(LeftTime%60);
    if(d > 0) {
      return `${d}D ${h}H ${m}M LEFT!`
    } else {
      return `${h}H ${m}M LEFT!`
    }
  }
  return (
    <Container id="what-is" ref={_ref}>
      <WrapSeason>
        <Season4Logo />
        <SeasonDuration>2022/10/29 11:00(JST) ~ <BreakSp />2022/11/13 20:00(JST)</SeasonDuration>
      </WrapSeason>
      <DonationRatio dog={DogRatio} cat={CatRatio} />
      <WrapPercentage>
        <EachPercentage>
          <Percentage>
            <Title>{t['dogs']}</Title>
            <Value>{DogRatio}%</Value>
          </Percentage>
          <Counts>
            {/*<TotalTitle>*/}
            {/*  <TotalValueTitle>Physical Request</TotalValueTitle>*/}
            {/*  <TotalValue>{PhysicalWAN}</TotalValue>*/}
            {/*</TotalTitle>*/}
            <TotalTitle>
              <TotalValueTitle>{t['total_gacha']}</TotalValueTitle>
              <TotalValue>{dogValue * 100}JPY</TotalValue>
            </TotalTitle>
          </Counts>
        </EachPercentage>
        <EachPercentage reverse>
          <Percentage reverse>
            <Title>{t['cats']}</Title>
            <Value>{CatRatio}%</Value>
          </Percentage>
          <Counts reverse>
            {/*<TotalTitle reverse>*/}
            {/*  <TotalValueTitle>Physical Request</TotalValueTitle>*/}
            {/*  <TotalValue>{PhysicalNYAN}</TotalValue>*/}
            {/*</TotalTitle>*/}
            <TotalTitleReverse>
              <TotalValueTitle>{t['total_gacha']}</TotalValueTitle>
              <TotalValue>{catValue * 100}JPY</TotalValue>
            </TotalTitleReverse>
          </Counts>
        </EachPercentage>
      </WrapPercentage>
      {/*<WrapAddressLink>*/}
      {/*  <ButtonLinkPopup href={getScanAddressURL(config.SEASON3_WAN_ADDRESS, config.NETWORK)} target={"_blank"}>*/}
      {/*    {t['check_donation_dogs']}*/}
      {/*  </ButtonLinkPopup>*/}
      {/*  <ButtonLinkPopup href={getScanAddressURL(config.SEASON3_NYAN_ADDRESS, config.NETWORK)} target={"_blank"}>*/}
      {/*    {t['check_donation_cats']}*/}
      {/*  </ButtonLinkPopup>*/}
      {/*</WrapAddressLink>*/}
      {/*<WrapAddressLink>*/}
      {/*  <ButtonLinkPopup*/}
      {/*    href={`https://twitter.com/intent/tweet?text=%5BWAN+NYAN+WARS+SEASON4%5D%0D%0AWAN+${DogRatio}%25+vs+NYAN+${CatRatio}%25%0D%0A${GetLeftTime()}%0A%0A&hashtags=WanNyanWars,WanWIN&url=https%3A%2F%2Fwan-nyan-wars.art&via=wannyanwars`}*/}
      {/*    target="_blank"*/}
      {/*  >*/}
      {/*    <Icon path={mdiTwitter} size={1} /> #WanWIN*/}
      {/*  </ButtonLinkPopup>*/}
      {/*  <ButtonLinkPopup*/}
      {/*    href={`https://twitter.com/intent/tweet?text=%5BWAN+NYAN+WARS+SEASON4%5D%0D%0AWAN+${DogRatio}%25+vs+NYAN+${CatRatio}%25%0D%0A${GetLeftTime()}%0A%0A&hashtags=WanNyanWars,NyanWIN&url=https%3A%2F%2Fwan-nyan-wars.art&via=wannyanwars`}*/}
      {/*    target="_blank"*/}
      {/*  >*/}
      {/*    <Icon path={mdiTwitter} size={1} /> #NyanWIN*/}
      {/*  </ButtonLinkPopup>*/}
      {/*</WrapAddressLink>*/}
      {/*<WrapSeasonLimit>*/}
      {/*  /!*<AlertNetwork>{t['alert_donation']}</AlertNetwork>*!/*/}
      {/*  <ButtonLinkInternal link="#overview">{t['button_join_the_war']}</ButtonLinkInternal>*/}
      {/*  <Countdown*/}
      {/*    date={config.SEASON4_TIME_LIMIT}*/}
      {/*    zeroPadTime={2}*/}
      {/*    zeroPadDays={2}*/}
      {/*    renderer={renderer}*/}
      {/*  />*/}
      {/*</WrapSeasonLimit>*/}
    </Container>
  )
}
