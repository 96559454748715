import fetch from 'isomorphic-unfetch'

const API_HOST = 'https://us-central1-wan-nyan-war.cloudfunctions.net/api/'
// const API_HOST = 'http://localhost:5001/wan-nyan-war/us-central1/api/'

const RESERVATION_PHYSICAL = 'reservation-physical'
const RESERVATION_COMPLETE = 'reservation-complete'
const DOWNLOAD_MODEL = 'download-model'
const EDITION = 'edition'

const apiFactory = (endpoint) => (`${API_HOST}${endpoint}`)

const queryFactory = (data) => {
  return {
    method: "POST",
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify({data})
  }
}

export const fetchReservationPhysical = async (data) => {
  return await fetch(apiFactory(RESERVATION_PHYSICAL), queryFactory(data)).then(res => res.json()).then(data => data)
}

export const fetchReservationComplete = async (data) => {
  return await fetch(apiFactory(RESERVATION_COMPLETE), queryFactory(data)).then(res => res.json()).then(data => data)
}

export const fetchEdition = async (data) => {
  return await fetch(apiFactory(EDITION), queryFactory(data)).then(res => res.json()).then(data => data)
}

export const fetchDownloadModel = async (data) => {
  return await fetch(apiFactory(DOWNLOAD_MODEL), queryFactory(data)).then(res => res.json()).then(data => data)
}
