export const i18n = {
  en: {
    nav_top: 'Top',
    nav_wan_nyan_wars: 'WAN NYAN WARS',
    hero_message: "ARE YOU\nA CAT PERSON OR\nA DOG PERSON?",
    dogs: 'Dogs',
    cats: 'Cats',
    name: 'Name',
    email: 'Email',
    country: 'Country',
    shipping_address: 'Shipping Address',
    wan_nyan_token_id: 'Wan or Nyan Token ID',
    donation_amount: 'Donation Amount (WETH(Polygon))',
    error_required: 'Required. Please enter this field.',
    error_email_format: 'Entered value does not match email format',
    error_different_network: 'Please change to Polygon Mainnet.',
    error_insufficient_funds: 'Funds are insufficient.',
    error_default: 'Some error occurred. Please reload.',
    success_default: 'Your request was completed successfully!',
    processing: 'Processing...',
    connected_wallet: 'Connected',
    total_donation: 'Total Donation',
    total_gacha: 'Total Gacha',
    check_donation_dogs: 'Check Donation For Dogs',
    check_donation_cats: 'Check Donation For Cats',
    button_buy_nft: 'BUY NFT',
    button_request_physical: 'REQUEST PHYSICAL',
    button_order_physical: 'ORDER PHYSICAL',
    button_metamask_connect: 'Connect to Metamask',
    button_goto_exhibition: 'GO TO EXHIBITION',
    button_join_the_war: 'JOIN THE WAR',
    button_submit: 'SUBMIT',
    button_cancel: 'CANCEL',
    edition_checker: 'Edition Checker',
    model_downloader: 'Model Downloader',
    overview_title: 'Project Overview',
    overview_section1_title: "“Wan” is a dog’s bow wow in Japan.,\n\"Nyan\" is a cat's meow in Japan.",
    overview_section1_desc: 'This is a war between dog people and cat people. Get an NFT of either a dog or a cat and support them! If you have an NFT, you can make a donation to your NFT character\'s side from the official website. If the character on your donated side wins, you\'ll get a piece of physical art with a blockchain certificate that looks exactly like your NFT!',
    overview_section2_title: 'NFT,\nPhysical,\nDonate,',
    overview_section2_desc: 'This is a collaborative art project between NFT purchasers/resellers, Physical requesters/resellers, donors, donation recipients, supporters of these ecosystems, and the artists. Donation recipients will be openly discussed on Github and Discord. All donations from the season will be given to the winning side\'s conservation organization. Wars will be held periodically. The timing and duration of the next season will be announced on Twitter and the official website. Be sure to check it out!\n',
    overview_get_nft: 'Do You Want To Get NFT?\nGet On OpenSea\n',
    overview_request_physical: 'Already have an NFT?\nGet Physical With Donate\n',
    overview_exhibition_gacha: 'Let\'s go to the\n"Digital Antiques Exhibition"',
    about_nft_title: 'About NFT Art',
    about_nft_section1_title: '100 Dogs NFTs & 100 Cats NFTs in Season 1',
    about_nft_section1_desc: 'In the first season, 100 NFTs will be issued for each dog and cat. These will all be completely different, and you will be able to find your favorite one.\n' +
      'Both of them consist of the parameters "PRIMARY COLOR, SECONDARY COLOR, ACCENT COLOR, BODY PATTERN, EYE, ODD EYE, FACE PATTERN, MOUTH, EAR, TAIL, LEG" respectively. Each part has its own rarity level. (To be released in the future)\n' +
      'Also, The final number of NFTs to be issued is limited to 1,000 each. ( This is due to the limited production capacity of physical art. 😂)',
    about_nft_section2_title: 'Start price is 0.01 WETH',
    about_nft_section2_desc: 'We will check the offers daily at 10pm (JST) and give them to the highest offeror. The minimum amount is 0.01 WETH. We would like people to feel free to participate in this project. Secondary sales are also welcome. We hope to get money for physical art production and shipping costs from the royalty.\n' +
      'We hope that as many NFTs as possible will be circulated and that we will get many physical requests. We are looking forward to producing many physical dog and cat artworks and getting them to you.',
    about_physical_title: 'About Physical Art',
    about_physical_section1_title: 'Physical art in the exact same form as NFT',
    about_physical_section1_desc: 'If you have an NFT, you can request physical art. The donation money granted for the request will be donated to the character side of the NFT you have.\n' +
      'And only those who request the side that wins the donation amount battle will receive the physical art.\n' +
      'Only a maximum of 10 physical editions will be issued from one NFT; please check the Edition Checker to see if you have any edition rights left before purchasing an NFT!\n' +
      'Also, Only one physical request can be made for one NFT (Token ID) per address.',
    about_physical_section2_title: 'Physical art with Startbahn cert.',
    about_physical_section2_desc: 'The blockchain certificate "Startbahn Cert" issued by Startbahn will be embedded in the physical art. This makes each piece of physical art an editioned and certified work of art. The Cert embedded in the physical art is equipped with an NFC chip, which allows the user to view the certificate of the artwork by holding up a smartphone. By inscribing history on a system that is difficult to tamper with, the physical art proves that it is not just an Toy figures, but an important value in this project.',
    about_physical_section2_caption: 'Photo is for illustrative purposes only.',
    support_title: 'Support from',
    team_title: 'Team',
    end_of_season: 'End of Season 4! Measuring is in progress...',
    alert_donation: 'Be careful! Only donations made through the Request physical form are valid.',
    edition_checker_alert: 'Includes reservations for this season (will be issued if this side win)',
    request_modal_alert: 'BE CAREFUL! DONATION ADDRESS CAN RECEIVE ONLY WETH(Polygon)',
    next_season_anno: 'See you in next season!',
  },
  ja: {
    nav_top: 'トップ',
    nav_wan_nyan_wars: 'WAN NYAN WARS',
    hero_message: 'あなたは\n犬派ですか？\n猫派ですか？',
    dogs: '犬派',
    cats: '猫派',
    name: '名前',
    email: 'メールアドレス',
    country: '国',
    shipping_address: '発送先住所',
    wan_nyan_token_id: 'WanもしくはNyanのトークンID',
    donation_amount: '寄付額(WETH(Polygon))',
    error_required: 'この項目は必須です',
    error_email_format: '無効なメールアドレスです',
    error_different_network: 'ネットワークをPolygonメインネットに変更してください',
    error_insufficient_funds: '資金が不十分です',
    error_default: '不明なエラーが発生しました。リロードして再度お試しください。',
    success_default: 'リクエストを受け付けました！',
    processing: '処理中...',
    connected_wallet: '接続済',
    total_donation: '寄付総額',
    total_gacha: 'ガチャ総額',
    check_donation_dogs: '犬への寄付を確認する',
    check_donation_cats: '猫への寄付を確認する',
    button_buy_nft: 'NFTを買う',
    button_request_physical: '物理を要求',
    button_order_physical: '物理を注文する',
    button_metamask_connect: 'Metamaskに接続',
    button_goto_exhibition: '展覧会に行く',
    button_join_the_war: '戦争に参加する',
    button_submit: '確認',
    button_cancel: 'キャンセル',
    edition_checker: 'エディション確認',
    model_downloader: 'モデルDL',
    overview_title: 'プロジェクト概要',
    overview_section1_title: "イヌはワンと吠え\nネコはニャンと鳴く",
    overview_section1_desc: 'これは、犬派と猫派の戦争です。犬か猫のどちらかのNFTを手に入れて、応援しましょう！ NFTをお持ちの方は、公式サイトからあなたのNFTキャラクター側に寄付をすることができます。寄付した側のキャラクターが勝つと、あなたのNFTとそっくりなブロックチェーン証明書付きの物理的なアート作品が手に入りますよ！',
    overview_section2_title: 'NFT,\n物理,\n寄付,',
    overview_section2_desc: 'この作品はNFT購入・転売者、物理要求・転売者、寄付者、寄付先、これらエコシステムを応援する人々、そしてアーティストによる共同アートプロジェクトです。寄付先はGithubやDiscordでオープンに議論し、シーズンで勝利した側の保護団体にそのシーズンで得た寄付金の全てを寄付します。（議論の結果次第で変わります）戦争は定期的に開催されます。次のシーズンの時期や期間はTwitterや公式サイトで発表します。ぜひチェックしてください！',
    overview_get_nft: 'NFTを入手しましょう！\nOpenSeaへどうぞ',
    overview_request_physical: 'NFTを持っていますか？\n物理を要求しましょう！',
    overview_exhibition_gacha: '「デジタル骨董展」に\n行って応援しよう！',
    about_nft_title: 'NFTアートについて',
    about_nft_section1_title: '犬のNFT100個、猫のNFT100個からスタート。',
    about_nft_section1_desc: '最初のシーズンでは犬と猫それぞれ100体ずつのNFTが発行されます。これらは全てまったく違うものになっていて、お気に入りの一匹を見つけてあげてください。\nどちらもそれぞれ「PRIMARY COLOR, SECONDARY COLOR, ACCENT COLOR, BODY PATTERN, EYE, ODD EYE, FACE PATTERN, MOUTH, EAR, TAIL, LEG」のパラメーターから構成されています。それぞれのパーツにはレアなものもあります。（パーツごとのレア度は今後発表します）\nまた、犬と猫のNFTはそれぞれ1000体までしか発行されません。（これは私たちの物理アートを作れる限界があるためです😂）',
    about_nft_section2_title: '初期価格は0.01WETH。',
    about_nft_section2_desc: '毎日22時（JST）にオファーを確認し、最も高い金額でオファーされている方にお譲りします。最低金額は0.01WETHです。私たちは気軽にこのプロジェクトに参加してもらいたいと考えています。二次流通も歓迎します。流通の際の還元金から物理アート制作のための費用や送料を得られればと思っています。\nなるべくたくさんのNFTが流通して、たくさんの物理リクエストがくることを望んでいます。物理犬猫アートをたくさん制作して皆さんの手元に届くことを楽しみにしています。',
    about_physical_title: '物理アートについて',
    about_physical_section1_title: 'NFTとそっくりな物理アート。',
    about_physical_section1_desc: 'NFTを持っている人は物理アートをリクエストすることができます。リクエストに付与する寄付金は持っているNFTのキャラクター側に寄付されます。\nそして、寄付金額バトルに勝利した側にリクエストした人のみが物理アートを受け取ることができます。1NFTからは最大で10物理エディションまでしか発行されません。NFTを購入する前にエディション発行権が残っているかどうかエディションチェッカーから確認してください。\nまた、一つのNFT（Token ID）について行える物理リクエストは1アドレス1回のみとなっております。',
    about_physical_section2_title: 'ブロックチェーン証明書が埋め込まれます。',
    about_physical_section2_desc: '物理アートには、スタートバーン社が発行するブロックチェーン証明書「Startbahn Cert」を埋め込みます。これにより物理アート一つ一つもエディションがついた証明された作品になります。物理アートに埋め込まれたCertにはNFCチップがついており、スマートフォンをかざすことで作品の証明書を閲覧することができます。改ざんのしにくい仕組みの上で歴史が刻まれていくことにより、物理アートがただのおまけではなく、このプロジェクトの中での重要な価値であることを証明します。',
    about_physical_section2_caption: '写真はイメージです。',
    support_title: 'サポート',
    team_title: 'チーム',
    end_of_season: 'Season 4終了!結果計測中です..',
    alert_donation: '物理リクエストフォームを通した寄付金のみが有効です。お気をつけください！',
    edition_checker_alert: '表示されるエディションは今シーズンで要求された未発行を含みます。（寄付側が勝利した場合のみ発行されます）',
    request_modal_alert: '受け取り可能な通貨はWETH(Polygon)のみです。お気をつけください！',
    next_season_anno: 'また次のシーズンでお会いしましょう',
  }
}
