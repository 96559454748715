import React from 'react'
import tw, {styled} from 'twin.macro'
import {useI18n} from 'use-mini18n'
import NormalNyanImg from '../../assets/images/NEKO_normal.png'
import NormalWanImg from '../../assets/images/INU_nomal.png'
import FlowImg from '../../assets/images/flow-explanation2-4x.png'
import Flow2Img from '../../assets/images/season4_winner.png'
import { ReactComponent as OpenSeaLogo } from '../../assets/images/logo-OpenSea.svg'
import { ButtonLinkExternal } from '../../components/ButtonLinkExternal'

const Container = tw.section`w-full mt-4 lg:mt-10 px-4 pb-16 lg:pr-44 lg:pl-16 transition-all duration-200`
const WrapContentRow = tw.div`relative mt-10 lg:mt-28 flex items-start lg:items-end lg:gap-x-12 flex-col lg:flex-row`
const WrapContentCol = tw.div`relative flex flex-col`
const Title = tw.h2`font-din-narrow font-extrabold text-3xl lg:text-7xl text-gray-100 whitespace-pre`
const Text = tw.p`leading-7 font-din text-lg font-semibold tracking-wide mt-3 lg:mt-10 w-full lg:w-1/2`
const BrOnlyPc = tw.br`hidden lg:block`
const WrapDeco = styled.div(() => [
  tw`relative lg:absolute top-0 right-0 w-full flex lg:gap-x-4 justify-evenly mt-5 lg:mt-0`,
  `@media (max-width: 1023px) {
     width: 100%;
     height: auto;
   }
   width: 35vw;
   height: 43vw;
   max-height: 170%;
   z-index: -1;`
])
const DecoNyan = tw.img`relative lg:absolute lg:top-0 lg:right-0 lg:w-3/5 lg:h-3/5 w-5/12 object-contain`
const DecoWan = tw.img`relative lg:absolute lg:bottom-0 lg:left-0 lg:w-3/5 lg:h-3/5 w-5/12 object-contain`
const Flow = tw.img`w-full mt-8 lg:mt-12`
const Flow2 = tw.img`w-full max-w-[600px] mx-auto mb-5 lg:mb-20`
const WrapJoinTheWar = tw.div`flex px-4 lg:px-12 mt-10 lg:mt-28 flex-col lg:flex-row`
const WrapWays = tw.div`w-full lg:w-1/2`
const TitleBlack = tw.h3`text-xl lg:text-3xl font-din font-bold mb-3 lg:mb-6 flex flex-col items-center whitespace-pre`
const WrapLogo = tw.div`w-24 lg:w-40 h-6 lg:h-10 mt-6`
const WrapButton = tw.div`w-52 lg:w-80 flex flex-col items-end mx-auto`
const WrapCenter = tw.div`flex-col flex items-center mb-10 lg:mb-24`

export const Overview = (props) => {
  const {t} = useI18n()
  const { _ref, openRequestModal } = props
  return (
    <Container id="overview" ref={_ref}>
      <WrapCenter>
        <Flow2 src={Flow2Img}/>
        <ButtonLinkExternal href="https://forms.gle/QQPh1qXWNQcSU5Fq7" target="_blank">{t['button_order_physical']}</ButtonLinkExternal>
      </WrapCenter>
      <WrapContentCol>
        <Title>{t['overview_section1_title']}</Title>
        <WrapDeco>
          <DecoNyan src={NormalNyanImg} />
          <DecoWan src={NormalWanImg} />
        </WrapDeco>
        <Text>{t['overview_section1_desc']}</Text>
      </WrapContentCol>
      <WrapContentRow>
        <Title>{t['overview_section2_title']}</Title>
        <Text>{t['overview_section2_desc']}</Text>
      </WrapContentRow>
      {/*<Flow src={FlowImg} />*/}
      <WrapJoinTheWar id="jointhewar">
        <WrapWays>
          <TitleBlack>{t['overview_get_nft']}</TitleBlack>
          <WrapButton>
            <ButtonLinkExternal href="https://opensea.io/collection/wan-nyan-wars" target="_blank">{t['button_buy_nft']}</ButtonLinkExternal>
            <WrapLogo>
              <OpenSeaLogo />
            </WrapLogo>
          </WrapButton>
        </WrapWays>
        {/*<WrapWays>*/}
        {/*  <TitleBlack>{t['overview_request_physical']}</TitleBlack>*/}
        {/*  <WrapButton>*/}
        {/*    <ButtonLinkExternal onClick={openRequestModal}>{t['button_request_physical']}</ButtonLinkExternal>*/}
        {/*  </WrapButton>*/}
        {/*</WrapWays>*/}
      </WrapJoinTheWar>
    </Container>
  )
}
