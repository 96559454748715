import React, {useEffect, useState} from 'react'
import { mdiLoading } from '@mdi/js'
import Icon from '@mdi/react'
import tw, {styled} from 'twin.macro'
import { useMoralis, useMoralisWeb3Api } from "react-moralis"
import { fetchReservationPhysical, fetchReservationComplete } from "../api"
import { useForm } from 'react-hook-form'
import { ButtonPopup } from "./ButtonPopup"
import config from '../const'
import Swal from 'sweetalert2'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { weth } from "../abi/weth"
import {useI18n} from "use-mini18n"

const Container = styled.div(({isScrollable}) => [
  tw`rounded-lg overflow-y-scroll`,
  `background-color: #c1c1c1;`,
  isScrollable && `height: 500px;`
])
const ContainerInner = tw.div`p-4`
const AlertNetwork = tw.p`font-din font-extrabold text-lg text-red-600 mb-4`
const WrapInput = tw.div`mb-4`
const InputLabel = tw.label`font-din text-gray-900`
const InputText = tw.input`block w-full p-2 border border-gray-300 text-gray-900 rounded-md`
const ErrorMessage = tw.p`font-din text-base text-red-500`
const WrapSubmit = tw.div`flex justify-center gap-x-4 mt-8`
const ProcessingText = tw.p`font-din text-gray-900`
const Form = tw.form``

export const RequestPhysicalModal = ({closeModal}) => {
  const {t} = useI18n()
  const [isProcessing, setIsProcessing] = useState(false)
  const [address, setAddress] = useState(null)
  const { web3, enableWeb3, isWeb3Enabled, isWeb3EnableLoading } = useMoralis()
  // const Web3Api = useMoralisWeb3Api()
  // const { fetch, data, error, isLoading}  = useMoralisWeb3ApiCall(Web3Api.token.getTokenIdOwners, options)
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    criteriaMode: "all",
    shouldFocusError: false,
  })

  useEffect(() => {
    const getAddress = async () => {
      if (isWeb3Enabled) {
        const result = await web3.eth.getAccounts()
        const network = await web3.eth.net.getId()
        setAddress(result[0])
        if (network !== 137) {
          await Swal.fire({
            text: t['error_different_network'],
            icon: 'error',
            confirmButtonText: 'OK'
          }).then(() => {
            window.location.reload()
          })
        }
      }
    }
    getAddress()
  }, [isWeb3Enabled, t, web3])

  const handleRequestPhysical = async (data) => {
    if (!address) window.location.reload()
    setIsProcessing(true)
    const contract = new web3.eth.Contract(weth, config.DONATION_TOKEN[config.NETWORK])
    const balance = await contract.methods.balanceOf(address).call();
    // const balance = await web3.eth.getBalance(address)
    if (Number(web3.utils.fromWei(balance, 'ether')) < Number(data.donation_amount)) {
      await Swal.fire({
        text: t['error_insufficient_funds'],
        icon: 'error',
        confirmButtonText: 'OK'
      })
      setIsProcessing(false)
      closeModal()
    } else {
      const request = {
        ...data,
        address,
      }
      const result = await fetchReservationPhysical(request)
      if (result.is_success) {
        const { id, type } = result
        const donationAddress = type === 'nyan' ? config.SEASON3_NYAN_ADDRESS : config.SEASON3_WAN_ADDRESS
        if (address && donationAddress && data && id) {
          const { donation_amount, token_id } = data
          contract
            .methods
            .transfer(donationAddress, web3.utils.toWei(donation_amount))
            .send({from: address})
            .on('transactionHash', async function(hash){
              const complete = await fetchReservationComplete({
                id,
                tx_hash: hash,
                type,
              })
              if (complete.is_success) {
                await Swal.fire({
                  text: t['success_default'],
                  icon: 'success',
                  confirmButtonText: 'OK'
                })
                setIsProcessing(false)
                closeModal()
              }
            })
          // web3.eth.sendTransaction({
          //   from: address,
          //   to: donationAddress,
          //   value: web3.utils.toWei(donation_amount),
          //   // data: web3.eth.abi.encodeParameter("bytes32", web3.utils.stringToHex(id)),
          // }, async (res, txHash) => {
          //   if (txHash) {
          //     const complete = await fetchReservationComplete({
          //       id,
          //       tx_hash: txHash,
          //       type,
          //     })
          //     if (complete.is_success) {
          //       await Swal.fire({
          //         text: t['success_default'],
          //         icon: 'success',
          //         confirmButtonText: 'OK'
          //       })
          //       setIsProcessing(false)
          //       closeModal()
          //     }
          //   }
          // })
        }
      } else {
        // Not owner or a limited number of editions have been applied for.
        await Swal.fire({
          text: t['error_default'],
          icon: 'error',
          confirmButtonText: 'OK'
        })
        setIsProcessing(false)
      }
    }
  }

  if (isProcessing) {
    return (
      <Container>
        <ContainerInner>
          <ProcessingText><Icon path={mdiLoading} spin={1} />{t['processing']}</ProcessingText>
        </ContainerInner>
      </Container>
    )
  }
  if(isWeb3Enabled) {
    return (
      <Container isScrollable>
        <PerfectScrollbar>
          <ContainerInner>
            <AlertNetwork>{t['request_modal_alert']}</AlertNetwork>
            <Form onSubmit={handleSubmit(handleRequestPhysical)}>
              <WrapInput>
                <InputLabel>{t['connected_wallet']}</InputLabel><br/>
                <InputLabel>{address}</InputLabel>
              </WrapInput>
              <WrapInput>
                <InputLabel>{t['name']}</InputLabel>
                <InputText type="text" {...register("name", { required: t['error_required'] })} />
                {errors.name && <ErrorMessage>{errors.name.message}</ErrorMessage>}
              </WrapInput>
              <WrapInput>
                <InputLabel>{t['email']}</InputLabel>
                <InputText type="text" {...register("email", {
                  required: t['error_required'],
                  pattern: {
                    value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                    message: t['error_email_format']
                  }
                })} />
                {errors.email && <ErrorMessage>{errors.email.message}</ErrorMessage>}
              </WrapInput>
              <WrapInput>
                <InputLabel>{t['country']}</InputLabel>
                <InputText type="text" {...register("country", { required: t['error_required'] })} />
                {errors.country && <ErrorMessage>{errors.country.message}</ErrorMessage>}
              </WrapInput>
              <WrapInput>
                <InputLabel>{t['shipping_address']}</InputLabel>
                <InputText type="text" {...register("shipping_address", { required: t['error_required'] })} />
                {errors.shipping_address && <ErrorMessage>{errors.shipping_address.message}</ErrorMessage>}
              </WrapInput>
              <WrapInput>
                <InputLabel>{t['wan_nyan_token_id']}</InputLabel>
                <InputText type="text" {...register("token_id", { required: t['error_required'] })} />
                {errors.token_id && <ErrorMessage>{errors.token_id.message}</ErrorMessage>}
              </WrapInput>
              <WrapInput>
                <InputLabel>{t['donation_amount']}</InputLabel>
                <InputText type="number" step="0.001" {...register("donation_amount", { required: t['error_required'] })} />
                {errors.donation_amount && <ErrorMessage>{errors.donation_amount.message}</ErrorMessage>}
              </WrapInput>
              <WrapSubmit>
                <ButtonPopup onClick={closeModal}>{t['button_cancel']}</ButtonPopup>
                <ButtonPopup type="submit">{t['button_submit']}</ButtonPopup>
              </WrapSubmit>
            </Form>
          </ContainerInner>
        </PerfectScrollbar>
      </Container>
    )
  } else {
    return (
      <Container>
        <ContainerInner>
          <ButtonPopup onClick={enableWeb3} disabled={isWeb3EnableLoading}>{t['button_metamask_connect']}</ButtonPopup>
        </ContainerInner>
      </Container>
    )
  }
}
