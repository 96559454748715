import React, {useState, useEffect} from 'react'
import tw, {styled} from 'twin.macro'
import {mdiTwitter, mdiDiscord} from '@mdi/js'
import Icon from '@mdi/react'
import {useI18n} from "use-mini18n"
import PentagonSvg from '../assets/images/pentagon.svg'
import AnchorLink from 'react-anchor-link-smooth-scroll'
import { useIntersection } from 'react-use';
import { ReactComponent as Logo } from '../assets/images/logo.svg'
import LineImg from '../assets/images/bg-cat-dog-one-line.jpg'
import {ButtonPopup} from "./ButtonPopup";

const Container = tw.div`fixed top-2.5 lg:top-8 right-0 lg:right-4 z-20`
const WrapLogo = tw.div`w-32 lg:w-60 absolute top-0 right-4`
const WrapLinkList = tw.ul`hidden lg:block mt-14`
const ListButton = tw.li`mb-5`
const LinkExternal = styled.li(() => [
  tw`text-right font-din mb-5 italic flex items-center justify-end`,
  `> a {
     padding: 0 6px;
     background-color: #c1c1c1;
     line-height: 1;
     cursor: pointer;
     &:hover {
       background-color: #878787;
     }
   }`,
])
const Link = styled.li(({current, target}) => [
  tw`text-right font-din mb-5 italic flex items-center justify-end`,
  `&:after {
     content: '';
     display: block;
     width: 14px;
     height: 16px;
     margin-left: 6px;
     background-image: url(${PentagonSvg});
     background-size: contain;
     background-repeat: no-repeat;  
   }
   > a {
     padding: 0 6px;
     background-color: #c1c1c1;
     line-height: 1;
     cursor: pointer;
     &:hover {
       background-color: #878787;
     }
   }`,
  current === target && `
    > a {
      background-color: #4f4f4f;
      color: #f5f5f5;
    }
  `
])
const WanNyanBar = styled.div(({isShow}) => [
  tw`fixed bottom-0 left-0 w-full h-12 lg:h-24`,
  `background-image: url(${LineImg});
   background-size: cover;
   background-position: 0 0;
   animation: moving2 300s linear infinite;
   transition: all 300ms;
   transform: translateY(100%);
   @keyframes moving2 {
     0% { background-position: 0 0; }
     100% { background-position: 1000% 0; }
   }`,
  isShow && `transform: translateY(0);`
])

const intersectionOptions = {
  root: null,
  rootMargin: '0px',
  threshold: 0
}

export const Navigation = (props) => {
  const {t} = useI18n()
  const [currentSection, setCurrentSection] = useState('top')
  const { heroRef, whatIsRef, overviewRef, aboutNftRef, aboutPhysicalRef, roadmapRef, supportRef, teamRef, openEditionModal, openRequestModal, openDownloadModal } = props
  const inViewHero = useIntersection(heroRef, intersectionOptions)
  const inViewWhatIs = useIntersection(whatIsRef, intersectionOptions)
  const inViewOverview = useIntersection(overviewRef, intersectionOptions)
  const inViewAboutNft = useIntersection(aboutNftRef, intersectionOptions)
  const inViewAboutPhysical = useIntersection(aboutPhysicalRef, intersectionOptions)
  const inViewRoadmap = useIntersection(roadmapRef, intersectionOptions)
  const inViewSupport = useIntersection(supportRef, intersectionOptions)
  const inViewTeam = useIntersection(teamRef, intersectionOptions)
  useEffect(() => {
    if (inViewHero && inViewHero.intersectionRatio > 0) setCurrentSection('top')
    if (inViewWhatIs && inViewWhatIs.intersectionRatio > 0) setCurrentSection('what-is')
    if (inViewOverview && inViewOverview.intersectionRatio > 0) setCurrentSection('overview')
    if (inViewAboutNft && inViewAboutNft.intersectionRatio > 0) setCurrentSection('about-nft')
    if (inViewAboutPhysical && inViewAboutPhysical.intersectionRatio > 0) setCurrentSection('about-physical')
    if (inViewRoadmap && inViewRoadmap.intersectionRatio > 0) setCurrentSection('roadmap')
    if (inViewSupport && inViewSupport.intersectionRatio > 0) setCurrentSection('support')
    if (inViewTeam && inViewTeam.intersectionRatio > 0) setCurrentSection('team')
  }, [inViewHero, inViewWhatIs, inViewOverview, inViewAboutNft, inViewAboutPhysical, inViewRoadmap, inViewSupport, inViewTeam])
  return (
    <Container>
      <WrapLogo>
        <Logo />
      </WrapLogo>
      <WrapLinkList>
        <Link current={currentSection} target={'top'}>
          <AnchorLink offset='70' href="#top">{t['nav_top']}</AnchorLink>
        </Link>
        <Link current={currentSection} target={'what-is'}>
          <AnchorLink offset='70' href="#what-is">"{t['nav_wan_nyan_wars']}"</AnchorLink>
        </Link>
        <Link current={currentSection} target={'overview'}>
          <AnchorLink offset='70' href="#overview">{t['overview_title']}</AnchorLink>
        </Link>
        <Link current={currentSection} target={'about-nft'}>
          <AnchorLink offset='70' href="#about-nft">{t['about_nft_title']}</AnchorLink>
        </Link>
        <Link current={currentSection} target={'about-physical'}>
          <AnchorLink offset='70' href="#about-physical">{t['about_physical_title']}</AnchorLink>
        </Link>
        {/*<Link current={currentSection} target={'roadmap'}>*/}
        {/*  <AnchorLink offset='70' href="#roadmap">Roadmap</AnchorLink>*/}
        {/*</Link>*/}
        <Link current={currentSection} target={'support'}>
          <AnchorLink offset='70' href="#support">{t['support_title']}</AnchorLink>
        </Link>
        <Link current={currentSection} target={'team'}>
          <AnchorLink offset='70' href="#team">{t['team_title']}</AnchorLink>
        </Link>
      </WrapLinkList>
      <WrapLinkList>
        <LinkExternal>
          <a href="https://twitter.com/wannyanwars" target={'_blank'} rel="noreferrer">Twitter</a>
          <p style={{marginLeft: '6px'}}><Icon path={mdiTwitter} size={0.75} /></p>
        </LinkExternal>
        <LinkExternal>
          <a href="https://discord.gg/MchDmCrr5n" target={'_blank'} rel="noreferrer">Discord</a>
          <p style={{marginLeft: '6px'}}><Icon path={mdiDiscord} size={0.75} /></p>
        </LinkExternal>
      </WrapLinkList>
      <WrapLinkList>
        <ListButton>
          <ButtonPopup onClick={openEditionModal}>{t['edition_checker']}</ButtonPopup>
        </ListButton>
        <ListButton>
          <ButtonPopup onClick={openDownloadModal}>{t['model_downloader']}</ButtonPopup>
        </ListButton>
        {/*<ListButton>*/}
        {/*  <ButtonPopup onClick={openRequestModal}>{t['button_request_physical']}</ButtonPopup>*/}
        {/*</ListButton>*/}
      </WrapLinkList>
      <WanNyanBar isShow={currentSection !== 'top'} />
    </Container>
  )
}
