import React from 'react'
import tw, {styled} from 'twin.macro'
import {useI18n} from "use-mini18n"
import { useWindowSize } from "react-use"
import BgImg from '../../assets/images/bg-cat-dog-line.png'
import BgDeco from '../../assets/images/FakeJapanese00-Whatis.svg'
import { ReactComponent as Logo } from '../../assets/images/logo.svg'
import { ReactComponent as LogoSp } from '../../assets/images/logo-sp.svg'
import { mdiTwitter } from '@mdi/js'
import Icon from '@mdi/react'

const Container = tw.section`w-full pt-24 lg:pt-60`
const BgBand = styled.div(() => [
  tw`relative h-48 lg:h-120`,
  `background-image: url(${BgImg});
   background-size: cover;
   background-position: 0 0;
   animation: moving 200s linear infinite;
   @keyframes moving {
     0% { background-position: 0 0; }
     100% { background-position: 1000% 0; }
   }`
])
const DecoFakeJapanese = styled.div(() => [
  tw`absolute right-0`,
  `width: 30vw;
   height: 38.9vw;
   background-image: url(${BgDeco});
   background-size: contain;
   background-repeat: no-repeat;
   z-index: -1;
   bottom: -34vw;
  `
])
const Message = tw.p`absolute text-4xl lg:text-8xl font-din font-extrabold text-gray-100 -top-16 lg:-top-36 px-4 lg:pr-44 lg:pl-16 whitespace-pre`
const WrapLogo = tw.div`relative -top-12 px-4 lg:pr-44 lg:pl-16`
const Twitter = tw.a`text-2xl lg:text-5xl text-gray-700 font-din-narrow font-light flex items-center gap-x-2 mt-4`

export const Hero = (props) => {
  const {t} = useI18n()
  const { _ref } = props
  const { width } = useWindowSize()
  return (
    <Container id="top" ref={_ref}>
      <BgBand>
        <Message>{t['hero_message']}</Message>
      </BgBand>
      <WrapLogo>
        { width > 1023 ? <Logo /> : <LogoSp /> }
        <Twitter href="https://twitter.com/intent/tweet?hashtags=WanNyanWars,nftart&url=https%3A%2F%2Fwan-nyan-wars.art&via=wannyanwars" target="_blank">
          <Icon path={mdiTwitter} size={width > 1023 ? 3 : 1.2} />
          <span>#WanNyanWars</span>
        </Twitter>
      </WrapLogo>
      <DecoFakeJapanese />
    </Container>
  )
}
