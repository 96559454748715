import React from 'react'
import tw, {styled} from 'twin.macro'
import {useI18n} from "use-mini18n"
import {ContentText, ContentTitle, SectionTitle, Highright} from "../../components/Typography"
import {WrapButtonCenter, WrapContentRow} from "../../components/Layout"
import {ButtonLinkExternal} from "../../components/ButtonLinkExternal"
import AboutPhysicalImg01 from '../../assets/images/about-physical-01.jpg'
import AboutPhysicalImg02 from '../../assets/images/about-physical-02.jpg'
import BgDeco from "../../assets/images/FakeJapanese03-AboutPhysicalArt.svg"

const Container = tw.section`
  relative w-full py-12 lg:py-16 px-4 lg:pr-44 lg:pl-16 transition-all duration-200
`

const DecoFakeJapanese = styled.div(() => [
  tw`absolute right-0`,
  `width: 30vw;
   height: 38.9vw;
   background-image: url(${BgDeco});
   background-size: contain;
   background-repeat: no-repeat;
   z-index: -1;
   top: 0;
   right: 0;
  `
])
const ContentsInner = tw.div``
const ContentImage = tw.img`mb-4 object-contain`
const ContentCaption = tw.p`text-sm font-din text-gray-600`
const WrapButton = tw.div`w-52 lg:w-80 flex flex-col items-end mx-auto`

export const AboutPhysical = (props) => {
  const {t} = useI18n()
  const { _ref, openRequestModal, openEditionModal } = props
  return (
    <Container id="about-physical" ref={_ref}>
      <DecoFakeJapanese />
      <SectionTitle>{t['about_physical_title']}</SectionTitle>
      <WrapContentRow>
        <ContentsInner>
          <ContentTitle>{t['about_physical_section1_title']}</ContentTitle>
          <ContentText>{t['about_physical_section1_desc']}</ContentText>
        </ContentsInner>
        <ContentsInner>
          <ContentImage src={AboutPhysicalImg01} />
        </ContentsInner>
      </WrapContentRow>
      <WrapContentRow reverse>
        <ContentsInner>
          <ContentImage src={AboutPhysicalImg02} />
          <ContentCaption>{t['about_physical_section2_caption']}</ContentCaption>
        </ContentsInner>
        <ContentsInner>
          <ContentTitle>{t['about_physical_section2_title']}</ContentTitle>
          <ContentText>{t['about_physical_section2_desc']}</ContentText>
        </ContentsInner>
      </WrapContentRow>
      {/*<WrapButtonCenter>*/}
      {/*  <WrapButton>*/}
      {/*    <ButtonLinkExternal onClick={openRequestModal}>{t['button_request_physical']}</ButtonLinkExternal>*/}
      {/*  </WrapButton>*/}
      {/*</WrapButtonCenter>*/}
    </Container>
  )
}
