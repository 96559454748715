import React from 'react'
import tw, {styled} from 'twin.macro'
import BattleCenterImage from '../assets/images/battle-center.svg'

const Container = tw.div``
const WrapBar = tw.div`flex w-full h-2 lg:h-6`
const RatioBar = styled.div(({ratio, bgColor, fluctuation, invert}) => [
  tw`relative h-full`,
  `will-change: width;
   width: ${ratio + (invert && fluctuation)}%;
   transition: all 500ms;
   background-color: ${bgColor};
   border-left: 2px solid #4f4f4f;
   border-right: 2px solid #4f4f4f;
   @media (max-width: 1023px) {
     border-left: 1px solid #4f4f4f;
     border-right: 1px solid #4f4f4f;
   }
   `,
  // !invert && `
  //   animation: fluctuation-invert 1s cubic-bezier(.76,1.7,.73,-0.68) infinite alternate;
  //   @keyframes fluctuation-invert {
  //    0% { width: calc(${ratio}% - ${fluctuation}%); }
  //    100% { width: calc(${ratio}% + ${fluctuation}%); }
  //   };
  // `
])
const BattleCenter = styled.div(() => [
  tw`absolute`,
  `width: 222px;
   height: 214px;
   top: -90px;
   right: -107px;
   background-image: url(${BattleCenterImage});
   background-size: contain;
   z-index: -1;
   animation: fluctuation-invert 1s cubic-bezier(.76,1.7,.73,-0.68) infinite alternate;
   @media (max-width: 1023px) {
     width: 65px;
     height: 62.5px;
     top: -32.5px;
     right: -32.25px;
     animation: fluctuation-invert-sp 1s cubic-bezier(.76,1.7,.73,-0.68) infinite alternate;
   }
   @keyframes fluctuation-invert {
     0% { right: -97px; }
     100% { right: -117px; }
   };
   @keyframes fluctuation-invert-sp {
     0% { right: -40px; }
     100% { right: -24.5px; }
   };
   `
])

export const DonationRatio = (props) => {
  const { dog, cat } = props
  const fluctuation = 1
  return (
    <Container>
      <WrapBar>
        <RatioBar ratio={dog} bgColor={'#e89258'} fluctuation={fluctuation} invert={false}>
          {/*<BattleCenter />*/}
        </RatioBar>
        <RatioBar ratio={cat} bgColor={'#c7a1db'} fluctuation={fluctuation} invert={true}/>
      </WrapBar>
    </Container>
  )
}
