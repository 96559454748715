import React from 'react'
import tw, {styled} from 'twin.macro'
import {useI18n} from 'use-mini18n'
import { SectionTitle, ContentTitle, ContentText, Highright } from '../../components/Typography'
import { WrapContentRow, WrapButtonCenter } from "../../components/Layout"
import { ButtonLinkExternal } from "../../components/ButtonLinkExternal"
import NyanNFT100 from '../../assets/images/NFTCats-only100-in1season-4x.png'
import WanNFT100 from '../../assets/images/NFTDogs-only100-in1season-1x-4x.png'
import Nyan3 from '../../assets/images/nya3.png'
import {ReactComponent as OpenSeaLogo} from "../../assets/images/logo-OpenSea.svg";
import BgDeco from "../../assets/images/FakeJapanese02-AboutNFTArt.svg";

const Container = styled.section(() => [
  tw`relative w-full py-12 px-4 lg:pr-44 lg:pl-16 transition-all duration-200 text-gray-100`,
  `background-color: #7a7a7a;`
])
const DecoFakeJapanese = styled.div(() => [
  tw`absolute right-0`,
  `width: 30vw;
   height: 38.9vw;
   background-image: url(${BgDeco});
   background-size: contain;
   background-repeat: no-repeat;
   z-index: 0;
   top: 0;
   right: 0;
  `
])
const ContentsInner = tw.div``
const ContentImage = tw.img`mb-4 object-contain`
const WrapLogo = tw.div`w-24 lg:w-40 h-6 lg:h-10 mt-6`
const WrapButton = tw.div`w-52 lg:w-80 flex flex-col items-end mx-auto`

export const AboutNft = (props) => {
  const { t } = useI18n()
  const { _ref } = props
  return (
    <Container id="about-nft" ref={_ref}>
      <DecoFakeJapanese />
      <SectionTitle>{t['about_nft_title']}</SectionTitle>
      <WrapContentRow>
        <ContentsInner>
          <ContentTitle>{t['about_nft_section1_title']}</ContentTitle>
          <ContentText>{t['about_nft_section1_desc']}</ContentText>
        </ContentsInner>
        <ContentsInner>
          <ContentImage src={NyanNFT100} />
          <ContentImage src={WanNFT100} />
        </ContentsInner>
      </WrapContentRow>
      <WrapContentRow reverse>
        <ContentsInner>
          <ContentImage src={Nyan3} />
        </ContentsInner>
        <ContentsInner>
          <ContentTitle>{t['about_nft_section2_title']}</ContentTitle>
          <ContentText>{t['about_nft_section2_desc']}</ContentText>
        </ContentsInner>
      </WrapContentRow>
      <WrapButtonCenter>
        <WrapButton>
          <ButtonLinkExternal href="https://opensea.io/collection/wan-nyan-wars" target="_blank">{t['button_buy_nft']}</ButtonLinkExternal>
          <WrapLogo>
            <OpenSeaLogo />
          </WrapLogo>
        </WrapButton>
      </WrapButtonCenter>
    </Container>
  )
}
