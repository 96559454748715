import firebase from 'firebase/compat/app'
import "firebase/compat/firestore"

export const collectionOptions = {
  snapshotListenOptions: {
    includeMetadataChanges: true
  }
}

const firebaseConfig = {
  apiKey: "AIzaSyBiziSamq96iGQmj3WEER8HbXuoU86zYW0",
  authDomain: "wan-nyan-war.firebaseapp.com",
  projectId: "wan-nyan-war",
  storageBucket: "wan-nyan-war.appspot.com",
  messagingSenderId: "931121571443",
  appId: "1:931121571443:web:adbfc8dbd9bb46d726ec06",
  measurementId: "G-8QN1371DTN"
};

firebase.initializeApp(firebaseConfig);
const db = firebase.firestore();
// if (process.env.REACT_APP_HOST_TYPE === 'development') db.useEmulator("localhost", 8080)
export default db;
