import React, {useRef, useEffect} from 'react'
import tw from 'twin.macro'
import {useI18n} from "use-mini18n"
import qs from 'query-string'
import { useModal } from 'react-hooks-use-modal'
import { Header } from '../../components/Header'
import { Navigation } from '../../components/Navigation'
import { Hero } from './hero'
import { Overview } from "./overview"
import { WhatIs } from "./what-is"
import { AboutNft } from "./about-nft"
import { AboutPhysical } from "./about-physical"
import { Support } from "./support"
import { Team } from './team'
import { Footer } from '../../components/Footer'
import { RequestPhysicalModal } from '../../components/RequestPhysicalModal'
import { EditionCheckerModal } from "../../components/EditionCheckerModal"
import { DownloadModelModal } from '../../components/DownloadModelModal'

const Container = tw.div`pb-12 lg:pb-24`

export const Home = () => {
  const queries = qs.parse(window.location.search)
  const { changeLang } = useI18n()
  useEffect(() => {
    if (queries.hi === 'ja') changeLang('ja')
    if (queries.hi === 'en') changeLang('en')
  }, [])
  const [RequestModal, openRequest, closeRequest] = useModal('root', {preventScroll: true, closeOnOverlayClick: false})
  const [EditionModal, openEdition, closeEdition] = useModal('root', {preventScroll: true})
  const [DownloadModal, openDownload, closeDownload] = useModal('root', {preventScroll: true})
  const HeroRef = useRef(null)
  const WhatIsRef = useRef(null)
  const OverviewRef = useRef(null)
  const AboutNftRef = useRef(null)
  const AboutPhysicalRef = useRef(null)
  const RoadmapRef = useRef(null)
  const SupportRef = useRef(null)
  const TeamRef = useRef(null)
  return (
    <Container>
      <RequestModal>
        <RequestPhysicalModal closeModal={closeRequest} />
      </RequestModal>
      <EditionModal>
        <EditionCheckerModal closeModal={closeEdition} />
      </EditionModal>
      <DownloadModal>
        <DownloadModelModal closeModal={closeDownload} />
      </DownloadModal>
      <Header
        openEditionModal={openEdition}
        openRequestModal={openRequest}
        openDownloadModal={openDownload}
      />
      <Navigation
        heroRef={HeroRef}
        whatIsRef={WhatIsRef}
        overviewRef={OverviewRef}
        aboutNftRef={AboutNftRef}
        aboutPhysicalRef={AboutPhysicalRef}
        roadmapRef={RoadmapRef}
        supportRef={SupportRef}
        teamRef={TeamRef}
        openEditionModal={openEdition}
        openRequestModal={openRequest}
        openDownloadModal={openDownload}
      />
      <Hero _ref={HeroRef} />
      <WhatIs _ref={WhatIsRef} />
      <Overview _ref={OverviewRef} openRequestModal={openRequest} />
      <AboutNft _ref={AboutNftRef} />
      <AboutPhysical
        _ref={AboutPhysicalRef}
        openRequestModal={openRequest}
        openEditionModal={openEdition}
      />
      {/*<Roadmap _ref={RoadmapRef} />*/}
      <Support _ref={SupportRef} />
      <Team _ref={TeamRef} />
      <Footer />
    </Container>
  )
}
