import React from 'react'
import tw, {styled} from 'twin.macro'
import {useI18n} from "use-mini18n"
// import { ButtonPopup } from "./ButtonPopup"

const Container = tw.header`pt-4 px-4 lg:pt-8 lg:px-16`
const WrapButtons = tw.div`flex flex-row gap-x-1 lg:gap-x-3`
const LangButton = styled.button(({selected}) => [
  tw`px-4 py-3 font-din font-bold text-lg font-normal w-3 h-1 flex justify-center items-center`,
  `color: #4f4f4f; background-color: #c1c1c1;`,
  selected && `
    color: #c1c1c1;
    background-color: #4f4f4f;
  `,
])

export const Header = ({openEditionModal, openRequestModal, openDownloadModal}) => {
  const {changeLang, lang, t} = useI18n()
  return (
    <Container>
      <WrapButtons>
        <LangButton onClick={() => changeLang('en')} selected={lang === 'en'}>EN</LangButton>
        <LangButton onClick={() => changeLang('ja')} selected={lang === 'ja'}>JA</LangButton>
        {/*<ButtonPopup onClick={openEditionModal}>{t['edition_checker']}</ButtonPopup>*/}
        {/*<ButtonPopup onClick={openDownloadModal}>{t['model_downloader']}</ButtonPopup>*/}
        {/*<ButtonPopup onClick={openRequestModal}>{t['button_request_physical']}</ButtonPopup>*/}
      </WrapButtons>
    </Container>
  )
}
