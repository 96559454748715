import React from 'react'
import { RecoilRoot } from 'recoil'
import { MoralisProvider } from "react-moralis"
import { TransProvider } from 'use-mini18n'
import { i18n } from './config/i18n'
import { Home } from './pages/home/index'
import configs from './const'
import 'react-perfect-scrollbar/dist/css/styles.css'


function App() {
  return (
    <RecoilRoot>
      <MoralisProvider
        appId={configs.MORALIS_ENV[configs.NETWORK].appId}
        serverUrl={configs.MORALIS_ENV[configs.NETWORK].server}
      >
        <TransProvider i18n={i18n}>
          <Home />
        </TransProvider>
      </MoralisProvider>
    </RecoilRoot>
  )
}

export default App;
