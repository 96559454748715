import tw, {styled} from 'twin.macro'

export const WrapContentRow = styled.div(({reverse}) => [
  tw`relative flex flex-col lg:flex-row gap-x-0 lg:gap-x-4 gap-y-4 lg:gap-y-0 mt-8 lg:mt-12 z-10`,
  !reverse ? `
    > div {
      &:nth-of-type(1) {
        width: calc(100% / 3 * 2);
      }
      &:nth-of-type(2) {
        width: calc(100% / 3 * 1);
      }
      @media (max-width: 1023px) {
        width: 100%!important;
      }
    }
  ` : `
    @media (max-width: 1023px) {
      flex-direction: column-reverse;
    }
    > div {
      &:nth-of-type(1) {
        width: calc(100% / 3 * 1);
      }
      &:nth-of-type(2) {
        width: calc(100% / 3 * 2);
      }
      @media (max-width: 1023px) {
        width: 100%!important;
      }
    }
  `
])

export const WrapContent2Row = tw.div`
  relative flex flex-col lg:flex-row gap-x-0 lg:gap-x-4 gap-y-8 lg:gap-y-0 mt-8 lg:mt-12 z-10
`

export const WrapButtonCenter = tw.div`flex items-center justify-center mt-8 lg:mt-12`
