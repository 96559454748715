import React, {useEffect, useState} from 'react'
import { mdiLoading } from '@mdi/js'
import {useI18n} from "use-mini18n"
import Icon from '@mdi/react'
import tw, {styled} from 'twin.macro'
import { useMoralis } from "react-moralis"
import { fetchDownloadModel } from "../api"
import { useForm } from 'react-hook-form'
import { ButtonPopup } from "./ButtonPopup"
import Swal from 'sweetalert2'
import PerfectScrollbar from 'react-perfect-scrollbar'

const Container = styled.div(() => [
  tw`rounded-lg overflow-y-scroll`,
  `background-color: #c1c1c1;`,
])
const ContainerInner = tw.div`p-4`
const WrapInput = tw.div`mb-4`
const InputLabel = tw.label`font-din text-gray-900`
const InputText = tw.input`block w-full p-2 border border-gray-300 text-gray-900 rounded-md`
const ErrorMessage = tw.p`font-din text-base text-red-500`
const WrapSubmit = tw.div`flex justify-center gap-x-4 mt-8`
const ProcessingText = tw.p`font-din text-gray-900`
const NftNameText = tw.p`font-din text-gray-900 text-lg`
const NftModelLink = tw.a`block mt-4 font-din text-blue-500 hover:text-blue-400 underline text-lg cursor-pointer`
const Form = tw.form``

export const DownloadModelModal = ({closeModal}) => {
  const {t} = useI18n()
  const [nftName, setNftName] = useState(null)
  const [nftModal, setNftModel] = useState(null)
  const [isProcessing, setIsProcessing] = useState(false)
  const [address, setAddress] = useState(null)
  const { web3, enableWeb3, isWeb3Enabled, isWeb3EnableLoading } = useMoralis()
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    criteriaMode: "all",
    shouldFocusError: false,
  })

  useEffect(() => {
    const getAddress = async () => {
      if (isWeb3Enabled) {
        const result = await web3.eth.getAccounts()
        setAddress(result[0])
      }
    }
    getAddress()
  }, [isWeb3Enabled, web3])

  const handleRequestPhysical = async (data) => {
    if (!address) window.location.reload()
    setIsProcessing(true)
    const request = {
      ...data,
      address,
    }
    const result = await fetchDownloadModel(request)
    if (result.is_success) {
      const { name, model } = result
      setNftName(name)
      setNftModel(model)
      setIsProcessing(false)
    } else {
      // Not owner.
      await Swal.fire({
        text: 'You are not owner.',
        icon: 'error',
        confirmButtonText: 'OK'
      })
      setIsProcessing(false)
    }
  }

  if (isProcessing) {
    return (
      <Container>
        <ContainerInner>
          <ProcessingText><Icon path={mdiLoading} spin={1} />{t['processing']}</ProcessingText>
        </ContainerInner>
      </Container>
    )
  }
  if (nftName && nftModal) {
    return (
      <Container>
        <ContainerInner>
          <NftNameText>{nftName}</NftNameText>
          <NftModelLink href={nftModal} target="_blank">{nftModal}</NftModelLink>
        </ContainerInner>
      </Container>
    )
  }
  if (isWeb3Enabled) {
    return (
      <Container>
        <PerfectScrollbar>
          <ContainerInner>
            <Form onSubmit={handleSubmit(handleRequestPhysical)}>
              <WrapInput>
                <InputLabel>{t['connected_wallet']}</InputLabel><br/>
                <InputLabel>{address}</InputLabel>
              </WrapInput>
              <WrapInput>
                <InputLabel>{t['wan_nyan_token_id']}</InputLabel>
                <InputText type="text" {...register("token_id", { required: t['error_required'] })} />
                {errors.token_id && <ErrorMessage>{errors.token_id.message}</ErrorMessage>}
              </WrapInput>
              <WrapSubmit>
                <ButtonPopup onClick={closeModal}>{t['button_cancel']}</ButtonPopup>
                <ButtonPopup type="submit">{t['button_submit']}</ButtonPopup>
              </WrapSubmit>
            </Form>
          </ContainerInner>
        </PerfectScrollbar>
      </Container>
    )
  } else {
    return (
      <Container>
        <ContainerInner>
          <ButtonPopup onClick={enableWeb3} disabled={isWeb3EnableLoading}>{t['button_metamask_connect']}</ButtonPopup>
        </ContainerInner>
      </Container>
    )
  }
}
