import React from 'react'
import tw, {styled} from 'twin.macro'

const Container = styled.a(() => [
  tw`relative font-din text-xs lg:text-base w-40 lg:w-52 h-6 lg:h-9 bg-gray-100 font-extrabold flex justify-center items-center cursor-pointer`
])
const DecoLeft = styled.div(() => [
  tw`absolute bottom-0 left-0`,
  `border-top: 6px solid transparent;
   border-right: 6px solid transparent;
   border-bottom: 6px solid #c1c1c1;
   border-left: 6px solid #c1c1c1;
   @media (max-width: 1023px) {
     border-width: 3px;
   }
  `
])
const DecoRight = styled.div(() => [
  tw`absolute top-0.5 lg:top-1 right-0.5 lg:right-1`,
  `border-top: 5px solid #4f4f4f;
   border-right: 5px solid #4f4f4f;
   border-bottom: 5px solid transparent;
   border-left: 5px solid transparent;
   @media (max-width: 1023px) {
     border-width: 2px;
   }
  `
])

export const ButtonLinkPopup = (props) => {
  const { children } = props
  return (
    <Container {...props}>
      <DecoLeft />
      {children}
      <DecoRight />
    </Container>
  )
}
